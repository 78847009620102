var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", "background-color": "#ffffff" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(false)
          },
          "head-saveBack": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c("div", { staticClass: "formMain" }, [
          _c("div", [
            _c(
              "div",
              { staticStyle: { padding: "0 12px" } },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: {
                      model: _vm.dataForm,
                      "label-width": "150px",
                      disabled: _vm.formType == "view",
                      rules: _vm.rules,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "formTopic",
                        staticStyle: { "margin-bottom": "12px" },
                      },
                      [_vm._v("基本信息")]
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "任务编码", prop: "taskCode" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: "",
                                    placeholder: "系统自动生成",
                                  },
                                  model: {
                                    value: _vm.dataForm.taskCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "taskCode", $$v)
                                    },
                                    expression: "dataForm.taskCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "任务名称", prop: "taskName" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入任务名称",
                                    maxlength: "50",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: _vm.dataForm.taskName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "taskName", $$v)
                                    },
                                    expression: "dataForm.taskName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "状态",
                                  prop: "acquisitionStatus",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择状态",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.dataForm.acquisitionStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "acquisitionStatus",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.acquisitionStatus",
                                    },
                                  },
                                  _vm._l(
                                    _vm.publishStatusOption,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.dictKey,
                                        attrs: {
                                          label: item.dictValue,
                                          value: item.dictKey,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "编制人", prop: "weaveUser" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: "",
                                    placeholder: "系统自动生成",
                                  },
                                  model: {
                                    value: _vm.dataForm.weaveUser,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "weaveUser", $$v)
                                    },
                                    expression: "dataForm.weaveUser",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "编制单位", prop: "weaveDept" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: "",
                                    placeholder: "系统自动生成",
                                  },
                                  model: {
                                    value: _vm.dataForm.weaveDept,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "weaveDept", $$v)
                                    },
                                    expression: "dataForm.weaveDept",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "模板来源",
                                  prop: "templateClassify",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择模板来源",
                                      clearable: false,
                                    },
                                    on: { change: _vm.chooseTemplateClassify },
                                    model: {
                                      value: _vm.dataForm.templateClassify,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "templateClassify",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.templateClassify",
                                    },
                                  },
                                  _vm._l(
                                    _vm.templateClassifyOption,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.dictKey,
                                        attrs: {
                                          label: item.dictValue,
                                          value: item.dictKey,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _vm.dataForm.templateClassify == 1
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "模板名称",
                                      prop: "templateCode",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择模板名称",
                                          clearable: "",
                                        },
                                        on: { change: _vm.chooseTemplate },
                                        model: {
                                          value: _vm.dataForm.templateCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "templateCode",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.templateCode",
                                        },
                                      },
                                      _vm._l(
                                        _vm.templateOptions,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.templateCode,
                                            attrs: {
                                              label: item.templateName,
                                              value: item.templateCode,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.dataForm.templateClassify == 2
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "模板名称",
                                      prop: "templateCode",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择模板名称",
                                          clearable: "",
                                        },
                                        on: { change: _vm.chooseTemplate },
                                        model: {
                                          value: _vm.dataForm.templateCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "templateCode",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.templateCode",
                                        },
                                      },
                                      _vm._l(
                                        _vm.reportTemplateOption,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.reportName,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tabs",
                      {
                        attrs: { type: "card" },
                        model: {
                          value: _vm.activeName,
                          callback: function ($$v) {
                            _vm.activeName = $$v
                          },
                          expression: "activeName",
                        },
                      },
                      [
                        _c("el-tab-pane", {
                          attrs: { label: "填报要求", name: "require" },
                        }),
                        _c("el-tab-pane", {
                          attrs: { label: "填报单位", name: "unit" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName == "require",
                            expression: "activeName == 'require'",
                          },
                        ],
                        staticStyle: { "margin-top": "12px" },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "标题：", prop: "title" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入标题",
                                        maxlength: "50",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value: _vm.dataForm.title,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.dataForm, "title", $$v)
                                        },
                                        expression: "dataForm.title",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "角色：", prop: "roleId" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          multiple: "",
                                          placeholder: "请选择角色",
                                        },
                                        on: { change: _vm.chooseRole },
                                        model: {
                                          value: _vm.dataForm.roleId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "roleId",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.roleId",
                                        },
                                      },
                                      _vm._l(_vm.roleData, function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.roleName,
                                            value: item.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "采集周期：",
                                      prop: "acquisitionCycleType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: { input: _vm.chooseCycle },
                                        model: {
                                          value:
                                            _vm.dataForm.acquisitionCycleType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "acquisitionCycleType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataForm.acquisitionCycleType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.acquisitionCycleOption,
                                        function (item, index) {
                                          return _c(
                                            "el-radio",
                                            {
                                              key: index,
                                              attrs: { label: item.dictKey },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(item.dictValue) +
                                                  "\n                      "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _vm.dataForm.acquisitionCycleType == 2
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "采集日期：",
                                          prop: "acquisitionCycle",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择采集日期",
                                            },
                                            on: { change: _vm.chooseCycWeek },
                                            model: {
                                              value:
                                                _vm.dataForm.acquisitionCycle,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "acquisitionCycle",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.acquisitionCycle",
                                            },
                                          },
                                          _vm._l(
                                            _vm.weekOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.dictKey,
                                                attrs: {
                                                  label: item.dictValue,
                                                  value: item.dictKey,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.dataForm.acquisitionCycleType == 3
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "采集日期：",
                                          prop: "acquisitionCycle",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择采集日期",
                                            },
                                            on: { change: _vm.chooseCycMonth },
                                            model: {
                                              value:
                                                _vm.dataForm.acquisitionCycle,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "acquisitionCycle",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.acquisitionCycle",
                                            },
                                          },
                                          _vm._l(
                                            _vm.dayOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.dictKey,
                                                attrs: {
                                                  label: item.dictValue,
                                                  value: item.dictKey,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.dataForm.acquisitionCycleType == 4
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "采集日期：",
                                          prop: "acquisitionCycle",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: {
                                              width: "50% !important",
                                            },
                                            attrs: {
                                              placeholder: "请选择月份",
                                            },
                                            on: { change: _vm.chooseYearMonth },
                                            model: {
                                              value: _vm.month,
                                              callback: function ($$v) {
                                                _vm.month = $$v
                                              },
                                              expression: "month",
                                            },
                                          },
                                          _vm._l(
                                            _vm.monthOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.dictKey,
                                                attrs: {
                                                  label: item.dictValue,
                                                  value: item.dictKey,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: {
                                              width: "50% !important",
                                            },
                                            attrs: {
                                              placeholder: "请选择日份",
                                            },
                                            model: {
                                              value: _vm.day,
                                              callback: function ($$v) {
                                                _vm.day = $$v
                                              },
                                              expression: "day",
                                            },
                                          },
                                          _vm._l(
                                            _vm.yearDayOption,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.dictKey,
                                                attrs: {
                                                  label: item.dictValue,
                                                  value: item.dictKey,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.dataForm.acquisitionCycleType == 5
                          ? _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "填报截至日期：",
                                          prop: "deadlineFillingDate",
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            type: "datetime",
                                            format: "yyyy-MM-dd HH:mm:ss",
                                            "value-format":
                                              "yyyy-MM-dd HH:mm:ss",
                                            "default-time": "23:59:59",
                                            placeholder: "请选择填报截至日期",
                                          },
                                          on: { change: _vm.handleDateChange },
                                          model: {
                                            value:
                                              _vm.dataForm.deadlineFillingDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm,
                                                "deadlineFillingDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "dataForm.deadlineFillingDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dataForm.acquisitionCycleType == 5
                          ? _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "汇总截至日期：",
                                          prop: "deadlineSummaryDate",
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            type: "datetime",
                                            format: "yyyy-MM-dd HH:mm:ss",
                                            "value-format":
                                              "yyyy-MM-dd HH:mm:ss",
                                            "default-time": "23:59:59",
                                            placeholder: "请选择汇总截至日期",
                                          },
                                          on: { change: _vm.handleDateChange },
                                          model: {
                                            value:
                                              _vm.dataForm.deadlineSummaryDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm,
                                                "deadlineSummaryDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "dataForm.deadlineSummaryDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dataForm.acquisitionCycleType != 5
                          ? _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "通知时间：",
                                          prop: "notificationTime",
                                        },
                                      },
                                      [
                                        _c("el-time-picker", {
                                          attrs: {
                                            "arrow-control": "",
                                            format: "HH:mm:ss",
                                            "value-format": "HH:mm:ss",
                                            placeholder: "请选择通知时间",
                                          },
                                          model: {
                                            value:
                                              _vm.dataForm.notificationTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm,
                                                "notificationTime",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "dataForm.notificationTime",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dataForm.acquisitionCycleType != 5
                          ? _c(
                              "el-row",
                              [
                                _vm.dataForm.acquisitionCycleType == 2
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "填报截止日期：",
                                              prop: "fillWeek",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-popover",
                                              {
                                                ref: "fillPopover",
                                                attrs: {
                                                  width: "400",
                                                  trigger: "manual",
                                                },
                                                on: {
                                                  show: _vm.openFillPopover,
                                                  hide: _vm.closeFillPopover,
                                                },
                                                model: {
                                                  value: _vm.fillWeekShow,
                                                  callback: function ($$v) {
                                                    _vm.fillWeekShow = $$v
                                                  },
                                                  expression: "fillWeekShow",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "weekBigBox" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "leftWeek",
                                                      },
                                                      _vm._l(
                                                        _vm.thisWeekAndNext,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "thisWeek",
                                                              style: {
                                                                color:
                                                                  _vm.thisWeekActive ==
                                                                  index
                                                                    ? _vm.themeColor
                                                                    : "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseThisWeek(
                                                                      item,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    item.title
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "rightWeek",
                                                      },
                                                      _vm._l(
                                                        _vm.thisWeekOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "weekLittleBox",
                                                              style: {
                                                                color:
                                                                  _vm.weekLittleActive ==
                                                                  index
                                                                    ? _vm.themeColor
                                                                    : "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseWeek(
                                                                      item,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    item.dictValue
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                    },
                                                    on: {
                                                      click: _vm.openFillWeek,
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.fillWeekText
                                                        ) +
                                                        _vm._s(
                                                          _vm.fillDayText
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.dataForm.acquisitionCycleType == 3
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "填报截止日期：",
                                              prop: "fillWeek",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-popover",
                                              {
                                                ref: "reportCycMonth",
                                                attrs: {
                                                  width: "400",
                                                  trigger: "manual",
                                                },
                                                on: {
                                                  show: _vm.openReportCycMonthPopver,
                                                  hide: _vm.closeReportCycMonthPopver,
                                                },
                                                model: {
                                                  value: _vm.reportMonthShow,
                                                  callback: function ($$v) {
                                                    _vm.reportMonthShow = $$v
                                                  },
                                                  expression: "reportMonthShow",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "weekBigBox" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "leftWeek",
                                                      },
                                                      _vm._l(
                                                        _vm.reportMonthAndNext,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "thisWeek",
                                                              style: {
                                                                color:
                                                                  _vm.reportMonthNextActive ==
                                                                  index
                                                                    ? _vm.themeColor
                                                                    : "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseReportMonthNext(
                                                                      item,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    item.title
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "rightMonth",
                                                      },
                                                      _vm._l(
                                                        _vm.reportMonthDayOption,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "weekLittleBox",
                                                              style: {
                                                                color:
                                                                  _vm.reportMonthDayActive ==
                                                                  index
                                                                    ? _vm.themeColor
                                                                    : "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseReportMonthDay(
                                                                      item,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    item.dictValue
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.openReportMonth,
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.reportMonthNextText
                                                        ) +
                                                        _vm._s(
                                                          _vm.reportMonthDayText
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.dataForm.acquisitionCycleType == 4
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "填报截止日期：",
                                              prop: "fillWeek",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-popover",
                                              {
                                                ref: "reportCycYear",
                                                attrs: {
                                                  width: "400",
                                                  trigger: "manual",
                                                },
                                                on: {
                                                  show: _vm.openReportCycYearPopver,
                                                  hide: _vm.closeReportCycYearPopver,
                                                },
                                                model: {
                                                  value: _vm.reportYearShow,
                                                  callback: function ($$v) {
                                                    _vm.reportYearShow = $$v
                                                  },
                                                  expression: "reportYearShow",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "weekBigBox" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "leftWeek",
                                                      },
                                                      _vm._l(
                                                        _vm.reportYearAndNext,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "thisWeek",
                                                              style: {
                                                                color:
                                                                  _vm.reportYearNextActive ==
                                                                  index
                                                                    ? _vm.themeColor
                                                                    : "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseReportYearNext(
                                                                      item,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    item.title
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "middleMonth",
                                                      },
                                                      _vm._l(
                                                        _vm.reportYearMonthOption,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "thisWeek",
                                                              staticStyle: {
                                                                "text-align":
                                                                  "center",
                                                              },
                                                              style: {
                                                                color:
                                                                  _vm.reportYearMonthActive ==
                                                                  index
                                                                    ? _vm.themeColor
                                                                    : "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseReportYearMonth(
                                                                      item,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    item.dictValue
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "rightMonth",
                                                        staticStyle: {
                                                          height: "308px",
                                                          width:
                                                            "calc(100% - 220px)",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.reportYearDayOption,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "weekLittleBox",
                                                              style: {
                                                                color:
                                                                  _vm.reportYearDayActive ==
                                                                  index
                                                                    ? _vm.themeColor
                                                                    : "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseReportYearDay(
                                                                      item,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    item.dictValue
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                    },
                                                    on: {
                                                      click: _vm.openReportYear,
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.reportYearNextText
                                                        ) +
                                                        _vm._s(
                                                          _vm.reportYearMonthText
                                                        ) +
                                                        _vm._s(
                                                          _vm.reportYearDayText
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dataForm.acquisitionCycleType != 5
                          ? _c(
                              "el-row",
                              [
                                _vm.dataForm.acquisitionCycleType == 2
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "汇总截止日期：",
                                              prop: "summaryWeek",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-popover",
                                              {
                                                ref: "sumPopover",
                                                attrs: {
                                                  width: "400",
                                                  trigger: "manual",
                                                },
                                                on: {
                                                  show: _vm.openSumPopover,
                                                  hide: _vm.closeSumPopover,
                                                },
                                                model: {
                                                  value: _vm.sumWeekShow,
                                                  callback: function ($$v) {
                                                    _vm.sumWeekShow = $$v
                                                  },
                                                  expression: "sumWeekShow",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "weekBigBox" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "leftWeek",
                                                      },
                                                      _vm._l(
                                                        _vm.thisSumWeekAndNext,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "thisWeek",
                                                              style: {
                                                                color:
                                                                  _vm.thisSumWeekActive ==
                                                                  index
                                                                    ? _vm.themeColor
                                                                    : "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseThisSumWeek(
                                                                      item,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    item.title
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "rightWeek",
                                                      },
                                                      _vm._l(
                                                        _vm.thisSumWeekOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "weekLittleBox",
                                                              style: {
                                                                color:
                                                                  _vm.weekSumLittleActive ==
                                                                  index
                                                                    ? _vm.themeColor
                                                                    : "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseSumWeek(
                                                                      item,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    item.dictValue
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                    },
                                                    on: {
                                                      click: _vm.openSumWeek,
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.sumWeekText
                                                        ) +
                                                        _vm._s(_vm.sumDayText) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.dataForm.acquisitionCycleType == 3
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "汇总截止日期：",
                                              prop: "summaryWeek",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-popover",
                                              {
                                                ref: "sumCycMonth",
                                                attrs: {
                                                  width: "400",
                                                  trigger: "manual",
                                                },
                                                on: {
                                                  show: _vm.openSumCycMonthPopver,
                                                  hide: _vm.closeSumCycMonthPopver,
                                                },
                                                model: {
                                                  value: _vm.sumMonthShow,
                                                  callback: function ($$v) {
                                                    _vm.sumMonthShow = $$v
                                                  },
                                                  expression: "sumMonthShow",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "weekBigBox" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "leftWeek",
                                                      },
                                                      _vm._l(
                                                        _vm.sumMonthAndNext,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "thisWeek",
                                                              style: {
                                                                color:
                                                                  _vm.sumMonthNextActive ==
                                                                  index
                                                                    ? _vm.themeColor
                                                                    : "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseSumMonthNext(
                                                                      item,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    item.title
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "rightMonth",
                                                      },
                                                      _vm._l(
                                                        _vm.sumMonthDayOption,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "weekLittleBox",
                                                              style: {
                                                                color:
                                                                  _vm.sumMonthDayActive ==
                                                                  index
                                                                    ? _vm.themeColor
                                                                    : "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseSumMonthDay(
                                                                      item,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    item.dictValue
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                    },
                                                    on: {
                                                      click: _vm.openSumMonth,
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.sumMonthNextText
                                                        ) +
                                                        _vm._s(
                                                          _vm.sumMonthDayText
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.dataForm.acquisitionCycleType == 4
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "汇总截止日期：",
                                              prop: "summaryWeek",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-popover",
                                              {
                                                ref: "sumCycYear",
                                                attrs: {
                                                  width: "400",
                                                  trigger: "manual",
                                                },
                                                on: {
                                                  show: _vm.openSumCycYearPopver,
                                                  hide: _vm.closeSumCycYearPopver,
                                                },
                                                model: {
                                                  value: _vm.sumYearShow,
                                                  callback: function ($$v) {
                                                    _vm.sumYearShow = $$v
                                                  },
                                                  expression: "sumYearShow",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "weekBigBox" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "leftWeek",
                                                      },
                                                      _vm._l(
                                                        _vm.sumYearAndNext,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "thisWeek",
                                                              style: {
                                                                color:
                                                                  _vm.sumYearNextActive ==
                                                                  index
                                                                    ? _vm.themeColor
                                                                    : "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseSumYearNext(
                                                                      item,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    item.title
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "middleMonth",
                                                      },
                                                      _vm._l(
                                                        _vm.sumYearMonthOption,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "thisWeek",
                                                              staticStyle: {
                                                                "text-align":
                                                                  "center",
                                                              },
                                                              style: {
                                                                color:
                                                                  _vm.sumYearMonthActive ==
                                                                  index
                                                                    ? _vm.themeColor
                                                                    : "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseSumYearMonth(
                                                                      item,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    item.dictValue
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "rightMonth",
                                                        staticStyle: {
                                                          height: "308px",
                                                          width:
                                                            "calc(100% - 220px)",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.sumYearDayOption,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "weekLittleBox",
                                                              style: {
                                                                color:
                                                                  _vm.sumYearDayActive ==
                                                                  index
                                                                    ? _vm.themeColor
                                                                    : "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseSumYearDay(
                                                                      item,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    item.dictValue
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                    },
                                                    on: {
                                                      click: _vm.openSumYear,
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.sumYearNextText
                                                        ) +
                                                        _vm._s(
                                                          _vm.sumYearMonthText
                                                        ) +
                                                        _vm._s(
                                                          _vm.sumYearDayText
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "上传附件：" } },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        staticClass: "upload-demo",
                                        attrs: {
                                          action:
                                            "api/sinoma-resource/oss/endpoint/put-file",
                                          "on-success": _vm.handleSuccess,
                                          "on-remove": _vm.handleRemove,
                                          headers: _vm.headers,
                                          multiple: "",
                                          limit: 1,
                                          "file-list": _vm.fileList,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "primary",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "点击上传\n                      "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "el-upload__tip",
                                            attrs: { slot: "tip" },
                                            slot: "tip",
                                          },
                                          [
                                            _vm._v(
                                              "\n                        支持扩展名：.rar .zip .doc .docx .pdf .jpg...\n                      "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "填写说明：" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        maxlength: "200",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value:
                                          _vm.dataForm.acquisitionDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "acquisitionDescription",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dataForm.acquisitionDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeName == "unit",
                        expression: "activeName == 'unit'",
                      },
                    ],
                    staticStyle: {
                      padding: "12px",
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { size: "small" },
                        on: { change: _vm.chooseGroup },
                        model: {
                          value: _vm.groupRadio,
                          callback: function ($$v) {
                            _vm.groupRadio = $$v
                          },
                          expression: "groupRadio",
                        },
                      },
                      _vm._l(_vm.groupOption, function (item, index) {
                        return _c("el-checkbox", {
                          key: index,
                          attrs: { label: item.groupName, border: "" },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeName == "unit",
                        expression: "activeName == 'unit'",
                      },
                    ],
                    staticClass: "shuttleBackBox",
                  },
                  [
                    _c("div", { staticClass: "shutleBack" }, [
                      _c("div", { staticClass: "shutleTitle" }, [
                        _vm._v("组织架构"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "shutContent" },
                        [
                          _vm.deptTreeShow
                            ? _c("el-tree", {
                                ref: "tree",
                                attrs: {
                                  data: _vm.deptChildTreeData,
                                  props: _vm.defaultProps,
                                  "node-key": "id",
                                  "show-checkbox": _vm.formType != "view",
                                  "check-strictly": true,
                                  "default-checked-keys":
                                    _vm.defaultCheckedKeys,
                                },
                                on: { "check-change": _vm.handleCheckChange },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ node }) {
                                        return [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: node.label,
                                                placement: "bottom",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "text" },
                                                [_vm._v(_vm._s(node.label))]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3891597954
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "shutleBack",
                        staticStyle: { width: "66%" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "unitBox" },
                          _vm._l(_vm.unitList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "unitTitle",
                                style: {
                                  color:
                                    _vm.unitActive == index
                                      ? _vm.themeColor
                                      : "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseUnit(index)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item) + "\n                ")]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          { staticClass: "shutContent" },
                          [
                            _vm._l(_vm.middleShutData, function (item, index) {
                              return _vm.unitActive == 0
                                ? _c(
                                    "div",
                                    { key: index, staticClass: "deptBox" },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.organize)),
                                      ]),
                                      item.userNameList != ""
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#2979FF" },
                                            },
                                            [
                                              _vm._v(
                                                "【" +
                                                  _vm._s(item.userNameList) +
                                                  "】"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e()
                            }),
                            _vm._l(_vm.rightShutData, function (item, index) {
                              return _vm.unitActive == 1
                                ? _c(
                                    "div",
                                    { key: index, staticClass: "deptBox" },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.organize)),
                                      ]),
                                      item.userNameList != ""
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#2979FF" },
                                            },
                                            [
                                              _vm._v(
                                                "【" +
                                                  _vm._s(item.userNameList) +
                                                  "】"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e()
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }